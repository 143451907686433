@charset "UTF-8";

/* ====== 1006 voice visual ====== */
#container.voice_container .main_wrap {background: url(../image/voice_visual1.png) no-repeat;}


/* ======= 1005 voice section1 ======= */
.voice_section1 {background-image: linear-gradient(to right, #9e57ed 0%, #664efd);}
.voice_section1 .section1_inner {height: 1698px;}
.voice_section1 .section1_box {width: 100%;}
.voice_section1 .section1_box ul {
    display: flex;
    justify-content: space-between;
}
.voice_section1 .section1_box ul li {cursor: default;} 


.voice_section1 .section1_box .content_txt {
    text-align: center;
    padding: 30px 0;
    background: #fff;
    border-radius: 0px 0px 22px 22px;
    height: 170px;
}

.voice_section1 .section1_box .content_txt p {
    font-size: 22px;
    color: #000;
    letter-spacing: -0.37px;
    padding-bottom: 15px;
}

.voice_section1 .section1_inner .content_txt span {
    font-size: 15px;
    letter-spacing: -0.34px;
    line-height: 2;
    color: #434343
}

/* 20230406 voice_section1 table */
#container .voice_section1 .section_title.small {
    font-size: 40px;
    line-height: 1.95;
    letter-spacing: -0.21px;
    color: #fff;
    text-align: left;
}

.voice_section1 .section_margin {margin-top: 80px;}

.voice_section1 .section1_table  {
    border-collapse: initial;
    border-spacing: 10px;
    font-family: "Montserrat";
    letter-spacing: -0.13px;
    color: #0e152e;
    font-size: 24px;
    margin-bottom: 20px;
}

.voice_section1 .section1_table .table_th {
    font-family: "Montserrat";
    padding: 10px 5px;
    word-break: normal;
    text-align: center;
}

.voice_section1 .section1_table .table_th.text_left {
    border-radius: 20px 20px 0 0;
    background-color: #fff;
    padding: 50px 30px 10px;
    height: 120px;
    box-sizing: border-box;
    text-align: left;
    font-weight: 700;
    background-image: repeating-linear-gradient(13deg, transparent ,  transparent 0 calc(50% - 1px), #9255f1 calc(50% - 1px) calc(50% + 0px));
}

.voice_section1 .section1_table .table_th.bg_green {
    border-radius: 20px 20px 0 0;
    background-color: #93face;
    object-fit: contain;
}

.voice_section1 .section1_table .table_th.bg_blue {
    border-radius: 20px 20px 0 0;
    object-fit: contain;
    background-color: #64ecff;
}


.voice_section1 .section1_table .table_td {
    padding:10px 5px 10px 14px;
    word-break: normal;
    text-align: center;
    position: relative;
    color: #fff;
}

.voice_section1 .section1_table .table_td.text_left {text-align: left;}
.voice_section1 .section1_table .table_td.figure {font-weight: 700;}
.voice_section1 .section1_table .table_td::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.2;
}

.voice_section1 .section1_box .info {
    font-size: 20px;
    color: #fff;
    letter-spacing: -0.11px;
    color: #fff;
}

.voice_section1 .section1_box .mayfarm_figure {font-weight: 700;}
.voice_section1 .section1_box .mayfarm_figure.num {text-decoration: underline;}


/* ======= 1005 voice section2 ======= */
.voice_section2 { 
    /* background: #111;  */
    background: #525252;
}

.voice_section2 .section2_inner {height: 860px;}
#container .voice_section2 .section2_inner .section_title,
#container .voice_section4 .section4_inner .section_title {
    text-align: left;
    font-size: 40px;
    line-height: 1.95;
    letter-spacing: -0.21px;
}
.voice_section2 .voice_wrap {
    width: 100%;
    height: 470px;
    padding-bottom: 20px;
}

.voice_section2 .voice_wrap .label_wrap {
    background: #212121;
    margin-bottom: 2px;
    width: 100%;
    height: 66px;
    padding: 10px 20px;
    box-sizing: border-box;
}

.voice_section2 .worko_tabs .state {
    position: absolute;
    left: -10000px;
}

.voice_section2 .worko_tabs .flex_tabs .file_upload {
    position: relative;
    width: 466px;
    display: none;
}

.voice_section2 .worko_tabs .flex_tabs .file_upload input[type="text"] {
    background: #fff;
    height: 41px;
    width: 300px;
    padding: 0 15px;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    background: #505050;
    color: #fff;
    opacity: 0.5;
    outline: none;
}

.voice_section2 .worko_tabs .flex_tabs .file_upload label {
    position: absolute;
    right: 50px;
    top: 0;
    width: 98px;
    height: 41px;
    cursor: pointer;
    text-align: center;
    line-height: 41px;
    font-weight: 500;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;
    opacity: 0.8;
}

input[type="file"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
}

.voice_section2 .worko_tabs .flex_tabs .panel {
    padding: 85px 0;
    display: none;
    width: 100%;
    box-sizing: border-box;
}

.voice_section2 .worko_tabs .flex_tabs .panel .btn_mike {
    background: transparent;
    border: none;
}

.voice_section2 .worko_tabs .flex_tabs .panel .btn_save {
    position: absolute;
    right: 30px;
    bottom: 40px;
    width: 60px;
    height: 60px;
    background: none;
    border: none;
}

.voice_section2 .worko_tabs .flex_tabs .txt_box {display: none;}
.voice_section2 .worko_tabs .tab {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    position: relative;
    font-size: 18px;
    letter-spacing: -0.07px;
    font-family: 'AppleSDGothicNeo';
    margin-right: 30px;
}

.voice_section2 .worko_tabs .tab.active:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -11px;
    width: 100%;
    height: 2px;
    background: #52f0ff;
}

.voice_section2 .worko_tabs .tab:hover {color: #fff;}

#tab_one:checked~.tabs #tab_one_label,
#tab_two:checked~.tabs #tab_two_label,
#tab_three:checked~.tabs #tab_three_label {
    cursor: default;
}

#tab_one:checked~.tabs .voice_box,
#tab_two:checked~.tabs .file_box,
#tab_three:checked~.tabs .sample_box {display: block;}

#tab_one:checked~.tabs #tab_one_panel,
#tab_two:checked~.tabs #tab_two_panel,
#tab_three:checked~.tabs #tab_three_panel {display: block;}

#tab_two:checked~.tabs .file_upload {display: inline-block;}



.voice_section2 .voice_wrap .comparison_btn {
    width: 180px;
    height: 45px;
    display: inline-block;
    float: right;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background: #464dee;
    border-radius: 4px;
    border: none;
    color: #fff;

}

.voice_section2 .voice_wrap .voice_left {
    position: relative;
    height: 400px;
    background: #212121;
    float: left;
    width: 40%;
}

.voice_section2 .voice_wrap #tab_one_panel,
.voice_section2 .voice_wrap #tab_three_panel {
    width: 230px;
    margin: 0 auto;
}

.voice_section2 .voice_wrap #tab_two_panel  {
    padding: 25px 20px;
}
.voice_section2 .voice_wrap #tab_two_panel .voice_spt {
    background: #000;
    height: 350px;
}

.voice_section2 .voice_wrap #tab_two_panel .spectrum {
    border: 1px solid blue;
    height: 250px;
}

.voice_section2 .voice_wrap #tab_two_panel .spectrum_play {
    display: flex;
    height: 100px;
    align-items: center;
    padding: 0px 20px;
}
.voice_section2 .voice_wrap #tab_two_panel .spectrum_play .btn_audioPlay {
    background: transparent; 
    border: none;
}

.voice_section2 .voice_wrap #tab_two_panel .spectrum_play span {
    font-size: 40px;
    font-weight: 700;
    padding-left: 16px;
}

.voice_section2 .voice_wrap #tab_three_panel .btn_sample_play {
    background: none;
    border: none;
}




.voice_section2 .voice_wrap .voice_right {
    width: 59.8%;
    height: 400px;
    background: #212121;
    float: right;
    padding: 25px 20px;
    box-sizing: border-box;
}

.voice_section2 .voice_wrap .voice_right .help_txt {
    font-size: 13px;
    font-weight: 600;
}

.voice_section2 .voice_right .txt_box {
    height: 350px;
    background: #000;
}

.voice_section2 .voice_right .txt_box p {
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    color: #fff;
    line-height: 1.75;
    letter-spacing: -0.06px;
    font-family: 'AppleSDGothicNeo';
    font-weight: 300;
}

.voice_section2 .section2_inner span {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.07px;
}

.voice_section2 .refer {
    color: #fff;
    letter-spacing: -0.07px;
    opacity: 0.6;
    font-size: 14px;
    text-align: left;
    width: 100%;
}



/* 1012 voice_popup */
.voice_section2 .voice_wrap.voice_popup {
    position: relative;
}

.voice_section2 .voice_popup .popup_box {
    position: absolute;
    top: 0;
    width: 1280px;
    height: 470px;
    background: #111;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 20px;
}

.voice_section2 .voice_popup .btn_close img {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 20px;
    height: 20px;
}

.voice_section2 .voice_popup .popup_box ul {display: flex;}
.voice_section2 .voice_popup .popup_box ul li {
    width: 33.3%;
    margin-right: 3px;
}

.voice_section2 .voice_popup .popup_box ul li .popup_tit {
    height: 66px;
    margin-bottom: 3px;
}

.voice_section2 .voice_popup .popup_box ul li .popup_tit.mayfarm_top {background: #212121;}
.voice_section2 .voice_popup .popup_box ul li .popup_tit.google_top {background: #1e84fc;}
.voice_section2 .voice_popup .popup_box ul li .popup_tit.naver_top {background: #00d059;}

.voice_section2 .voice_popup .popup_box ul li .popup_tit p {
    line-height: 66px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    letter-spacing: -0.07px;
}

.voice_section2 .voice_popup .popup_box ul li .popup_btm {
    background: #212121 ;
    padding: 16px 14px;
}

.voice_section2 .voice_popup .popup_box ul li .btm_inner {
    background: #171717;
    height: 290px;
    padding: 20px;
    box-sizing: border-box;
}


/* ======= 1007 voice section3 ======= */
.voice_section3 {background: #eaf3fe;}
.voice_section3 .section3_inner { height: 1850px;}
#container .voice_section3 .section_title {color: #464dee;}
.voice_section3 .section3_box .section3_img img {margin-top: 100px;}


/* ======= 1007 voice section4 ======= */
.voice_section4 {background: #000;}
.voice_section4 .section4_inner {height: 800px;}

.voice_section4 .voice_wrap {width: 100%;}
.voice_section4 .voice_wrap .voice_top {
    background: #212121;
    height: 46px;
    font-family: 'AppleSDGothicNeo';
    line-height: 1.75;
    letter-spacing: -0.06px;
    color: #fff;
    padding: 20px;
}

.voice_section4 .voice_wrap .voice_middle {
    height: 86px;
    margin: 3px 0;
    display: flex;
    justify-content: space-between;
}


.voice_section4 .voice_middle .box_left {
    width: 33.3%;
    height: 86px;
    background: #212121;
    float: left;
    margin-right: 4px;
    padding: 15px 16px 10px 16px;
    box-sizing: border-box;
}

.voice_section4 .voice_middle .box_left .box_img {float: left;}
.voice_section4 .voice_middle .box_left .btn_wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.voice_section4 .voice_middle .box_left .btn_wrap button {
    font-family: 'AppleSDGothicNeo';
    border: none;
    margin-right: 8px;
    border-radius: 4px;
    background: #505050;
    color: #fff;
    letter-spacing: -0.06px;
    padding: 10px 23px;
    cursor: default;
}

.voice_section4 .voice_middle .box_left .btn_wrap button:last-child {margin-right: 0;}

.voice_section4 .voice_middle .box_middle {margin-right: 4px;}
.voice_section4 .voice_middle .box_middle,
.voice_section4 .voice_middle .box_right {
    width: 33.3%;
    height: 100%;
    background: #212121;
    display: inline-block;
}


.voice_section4 .middle_inner {padding: 30px 10px;}
.voice_section4 .middle_inner span {
    display: inline-block;
    font-family: 'AppleSDGothicNeo';
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    padding-left: 20px;
    margin-right: 30px;
    vertical-align: middle;
}

.voice_section4 .middle_inner input[type="range"] {
    width: 280px;
    background-color: red;
}

/* 05230517 input type="range' 커스텀 */
#voice_man {margin-top: 30px;}

/* 20230410 section4 voice_bottom txt_box */
.voice_section4 .voice_bottom .txt_box_wrap {
    background: #171717;
    height: 200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.voice_section4 .voice_bottom .txt_box_wrap.margin {margin-top: 12px;}

.voice_section4 .voice_bottom .txt_box_wrap .txt_box p {
    color: #fff;
    font-family: 'AppleSDGothicNeo';
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: -0.06px;
}

.voice_section4 .voice_bottom .txt_box_wrap .txt_box {
    height: 100%;
    width: 100%;
    margin-right: 30px;
    box-sizing: border-box;
}

.voice_section4 .voice_bottom .txt_box_wrap .txt_box textarea {
    height: 100%;
    width: 100%;
    background: #171717;
    border: none;
    color: #fff;
    font-family: 'AppleSDGothicNeo';
}

.voice_section4 .voice_bottom .txt_box_wrap .synthesis_btn_box {
    display: flex;
}


.voice_section4 .voice_bottom .txt_box_wrap .btn_synthesis {
    width: 130px;
    height: 60px;
    margin-right: 10px;
    line-height: 60px;
    object-fit: contain;
    border-radius: 4px;
    background-image: linear-gradient(to right, #9e57ed 0%, #664efd);
    color: #fff;
}

.voice_section4 .voice_middle .box_right {float: right;}

.voice_section4 .voice_wrap .voice_bottom {
    width: 100%;
    background: #212121;
    margin-top: 3px;
    padding: 20px;
    box-sizing: border-box;
}


/* ======= 1007 voice section5 ======= */
.voice_section5 {background: #382496;}
.voice_section5 .section5_inner {height: 910px;}
#container .voice_section5 .section5_inner .section_title.small {color: #fff;}

.voice_section5 .section5_box {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.voice_section5 .section5_inner .content  {
    background: #fff;
    padding: 30px;
    height: 440px;
    border-radius: 20px;
}

.voice_section5 .section5_box .content img {height: 300px;}
.voice_section5 .section5_box .content:last-child {margin-right: 0;}
.voice_section5 .section5_box .content .content_txt {text-align: center; cursor: default;}

.voice_section5 .section5_box .content .content_txt p {
    font-size: 30px;
    color: #0e152e;
    font-family: "GongGothic";
    padding: 20px 0 10px;
}