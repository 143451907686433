@charset "UTF-8";
@font-face {
    font-family: 'GongGothic';
    font-style: normal;
    font-weight: 400;
    src: url(../font/GongGothicOTF-Medium.otf) format('embedded-opentype'),
         url(../font/GongGothic-Medium.woff) format('woff'),/* 최신브라우져 */
         url(../font/GongGothic-Medium.ttf) format('truetype');/* 기타브라우져 */
}
@font-face {
    font-family: 'GongGothic';
    font-style: normal;
    font-weight: 700;
    src: url(../font/GongGothicOTF-Bold.otf) format('embedded-opentype'),
         url(../font/GongGothic-Bold.woff) format('woff'),/* 최신브라우져 */
         url(../font/GongGothic-Bold.ttf) format('truetype');/* 기타브라우져 */
}

@font-face {
    font-family: 'SpoqaHanSansNeo';
    font-style: normal;
    font-weight: 300;
    src: url(../font/SpoqaHanSansNeo-Light.eot);/* ie 6-9 */
    src: url(../font/SpoqaHanSansNeo-Light.otf) format('embedded-opentype'),
         url(../font/SpoqaHanSansNeo-Light.woff) format('woff'),/* 최신브라우져 */
         url(../font/SpoqaHanSansNeo-Light.ttf) format('truetype');/* 기타브라우져 */
}

@font-face {
    font-family: 'SpoqaHanSansNeo';
    font-style: normal;
    font-weight: 400;
    src: url(../font/SpoqaHanSansNeo-Medium.eot);/* ie 6-9 */
    src: url(../font/SpoqaHanSansNeo-Medium.otf) format('embedded-opentype'),
         url(../font/SpoqaHanSansNeo-Medium.woff) format('woff'),/* 최신브라우져 */
         url(../font/SpoqaHanSansNeo-Medium.ttf) format('truetype');/* 기타브라우져 */
}

@font-face {
    font-family: 'SpoqaHanSansNeo';
    font-style: normal;
    font-weight: 700;
    src: url(../font/SpoqaHanSansNeo-Bold.eot);/* ie 6-9 */
    src: url(../font/SpoqaHanSansNeo-Bold.otf) format('embedded-opentype'),
         url(../font/SpoqaHanSansNeo-Bold.woff) format('woff'),/* 최신브라우져 */
         url(../font/SpoqaHanSansNeo-Bold.ttf) format('truetype');/* 기타브라우져 */
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-style: normal;
    font-weight: 300;
    src: url(../font/AppleSDGothicNeoL.otf) format('embedded-opentype'),
         url(../font/AppleSDGothicNeoL.woff) format('woff'),/* 최신브라우져 */
         url(../font/AppleSDGothicNeoL.ttf) format('truetype');/* 기타브라우져 */
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-style: normal;
    font-weight: 500;
    src: url(../font/AppleSDGothicNeoSB.otf) format('embedded-opentype'),
         url(../font/AppleSDGothicNeoSB.woff) format('woff'),/* 최신브라우져 */
         url(../font/AppleSDGothicNeoSB.ttf) format('truetype');/* 기타브라우져 */
}


@font-face {
    font-family: 'AppleSDGothicNeo';
    font-style: normal;
    font-weight: 700;
    src: url(../font/AppleSDGothicNeoB.otf) format('embedded-opentype'),
         url(../font/AppleSDGothicNeoB.woff) format('woff'),/* 최신브라우져 */
         url(../font/AppleSDGothicNeoB.ttf) format('truetype');/* 기타브라우져 */
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src: url(../font/Gotham-Bold.woff) format('woff'),/* 최신브라우져 */
         url(../font/Gotham-Bold.ttf) format('truetype');/* 기타브라우져 */
}


html {overflow-y:scroll;}

html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,address,code,em,img,strong,sub,sup,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figure,figcaption,footer,header,hgroup,menu,nav,section,main,audio,video {margin: 0; padding: 0;}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main {display: block}
button {cursor:pointer}
fieldset,img {border: 0; vertical-align: top}
ol,ul {list-style: none}
address,em {font-style: normal}
a {text-decoration: none; color: #fff;}
iframe {overflow: hidden; border: 0}
table {border-collapse: collapse; border-spacing: 0; width: 100%}
h1,h2,h3,h4,h5,h6 {font-weight: normal; font-size: 100%}
input,select,textarea,button {
    vertical-align: middle;
    margin: 0;
    padding: 0;
    border-radius: 0;
}
button,select {-webkit-appearance:none; appearance: none;}
textarea {resize: none}
body {line-height: 1.5; -webkit-text-size-adjust:none}
legend,caption,.blind {position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px}


/************** 공통스타일 *************/
body,input,button,select,textarea,table {
    font-family: "SpoqaHanSansNeo", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color:#666;
}

body {
    -ms-overflow-style: none; /* IE, Edge */
    scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
    display: none;
}


/* 스킵네비게이션 */
#skipNavi {position: relative;}
#skipNavi a {
    position: absolute;
    left:0;
    top:-9999px;
    width:100%;
    line-height: 40px;
    text-align: center;
    background: #ddd;
    font-size: 14px;
}
#skipNavi a:hover,
#skipNavi a:active,
#skipNavi a:focus {
    top:0;
}


/* 0930 header gnb */
#header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 70px;
    z-index: 9999;
    min-width: 1280px;
}

/* header 각 페이지 color 처리로 important 사용 */
#header.on {background: #fff;}
#header.on .gnb .depth1 li a  {color: #000 !important}
#header .gnb li.active a {color: #464dee !important;}
#header.on .gnb li.active a {color: #464dee !important;}

#header .header_inner {
    display: flex;
    justify-content: space-between;
    width: 1280px;
    height: 70px;
    line-height: 70px;
    margin: 0 auto;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

#header .header_inner .logo img {
    vertical-align: middle;
}

#header .header_inner .gnb .depth1 {
    float: right;
}

#header .header_inner .gnb .depth1 li {
    float: left;
    margin-right: 40px;
}

#header .header_inner .gnb .depth1 li {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
}


#header .header_inner .gnb .depth1 li:last-child {
    margin-right: 0;
    font-family: "SpoqaHanSansNeo", sans-serif;
    font-weight: 700;
}

#header .header_inner .gnb .depth1 li a {padding: 10px;}

#container {background: #111;}

/* 20230324 메인비주얼 공통*/
#container .main_wrap {
    height: 100vh;
    background-size: cover !important;
}

/* 0930 메인비주얼 타이틀 공통 */
#container .main_visual_title {
    width: 1280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: -130px;
    margin: 0 auto;
    align-items: flex-start;
}

#container .main_visual_title p {
    font-size: 56px;
    color: #434343;  
    letter-spacing: -0.3px;
    font-family: "GongGothic", sans-serif;
}

#container .main_visual_title span {
    color: #1f1f1f;
    font-size: 20px;
}


/* 20230324 공통 inner */
.com_inner {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* 20230403 섹션타이틀 공통 */
#container .section_title {
    font-size: 56px;
    color: #fff;
    font-family: "GongGothic";
    padding: 0 0 40px;
    text-align: center;
    cursor: default;
    width: 100%;
}

/* 섹션타이틀 블루색상 공통 */
#container .section_title.blue {
    font-size: 56px;
    color: #464dee;
}

/* 섹션 작은타이틀(영어) 블루색상 공통 */
#container .section_title.small {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 700;
    color: #464dee;
    padding-bottom: 20px;
}


/* 20230322 데모체험하기 공통 */
.com_experience {position: relative;}
.com_experience img {width: 100%;}
.com_experience .btn_demo {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -130px;
    margin-top: 18px;
    width: 262px;
    height: 64px;
    border: 0;
    background: #464dee;
    border-radius: 8px;
    color: #fff;
    font-size: 20px;
}

.com_experience .btn_demo.margin_top {
    margin-top: 0;
}


/* 1004 footer */
#footer {
    background: #fff;
    cursor: default;
    display: flex;
}

.mayfarm_logo {
    height: 120px;
    line-height: 110px;
    margin: 0 40px 0 60px;
    width: 180px;
}

.mayfarm_logo img {vertical-align: middle; width: 100%;}

#footer .footer_inner {
    display: flex;
    height: 120px; 
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
}

#footer .footer_inner h4 {
    float: left;
    color: #505050;
    margin-right: 20px;
}

#footer .footer_address {
    display: inline-block;
    color: #707070;
}

#footer dt, #footer dd {display: inline-block;}
#footer .footer_address dl:first-child dd {margin-left: 180px;}
#footer .footer_address dd {margin-left: 195px;}

#footer .footer_address dt:first-child {
    position: relative;
    margin-right: 10px;
}

/* #footer .footer_address dt:first-child:after {
    content: '';
    position: absolute;
    right: -7px;
    top: 6px;
    width: 1px;
    height: 13px;
    background: #d4d4d4;
} */

#footer .footer_inner .footer_tel{
    display: inline-block;
    line-height: 41px;
    margin-right: 80px;
    color: #505050;
}

#footer .footer_inner .footer_right {
    display: flex;
    justify-content: space-between;
}

/* 20230503 패밀리사이트 */
#footer .family_site {
    width: 150px;
    height: 41px;
    position: relative;
}

#footer .family_site .btn_family {
    width: 100%;
    height: 41px;
    background: #fff;
    border: 1px solid #777;
    outline: none;
    font-size: 15px;
    color: #666;
    text-align: left;
    padding-left: 10px;
    position: relative;
}

#footer .family_site .btn_family:after {
    content: '';
    position: absolute;
    right: 15px;
    top: 16px;
    width: 12px;
    height: 8px;
    background: url(../image/icon_arrow.png) no-repeat;
    transform: rotate(180deg);
}

#footer .family_site .btn_family.on:after {
    transform: rotate(0);
}

#footer .family_site .family_list {
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100%;
    background: #fff;
    border: 1px solid #777;
    box-sizing: border-box;
    padding: 10px 0;
}

#footer .family_site .family_list li {
    padding-left: 10px;
    line-height: 30px;
}

#footer .family_site .family_list li a {
    color: #666;
    display: block;
    font-size: 14px;
}


/* 20230516 공통 스피너, dim 처리 */
.dim {
    position: fixed; 
    top: 0px; 
    left: 0px; 
    width: 100%; 
    height: 100%;
    z-index: 100; 
    opacity: 0.5; 
    background-color: rgb(0, 0, 0)
}

.loading_position {
    position: relative;
}
.loading {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spinner 1s ease-in-out infinite;
    -webkit-animation: spinner 1s ease-in-out infinite;
}

@keyframes spinner {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spinner {
    to { -webkit-transform: rotate(360deg); }
}

@media screen and (max-width: 1920px) {
    #container {
        width: 1920px;
    }
    #footer {
        width: 1920px;
    }
}




