@charset "UTF-8";

/* ====== 1014 inquiry visual ====== */
#container.inquiry_container .main_visual_title p {
    font-size: 60px;
    letter-spacing: -0.22px;
}
#container.inquiry_container span {
    font-size: 20px;
    line-height: 1.7;
    letter-spacing: -0.25px;
    color: #fff;
}



/* ====== 1014 inquiry section1 ====== */
.inquiry_section1 {
    background: #fafaff;
    margin-top: -3px;
}

.inquiry_section1 .section1_inner {height: 1150px;}


/* 20230403 seciton_title 수정필요 */
#container .inquiry_section1 .section_title {
    font-family: 'Montserrat';
    font-weight: 700;
    text-align: left;
    color: #0e152e;
    padding: 0 0 20px;
}

.inquiry_section1 .tbl_inquiry tbody {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.inquiry_section1 .tbl_inquiry tbody .flex_basis {flex-basis: 48%;}

.inquiry_section1 .tbl_inquiry tbody th, .inquiry_section1 .tbl_inquiry td {display: block;}
.inquiry_section1 .tbl_inquiry tbody th {
    text-align: left; 
    color: #51565d;
    margin: 30px 0 0 10px;
    font-size: 14px;
}

#container .inquiry_section1 .tbl_inquiry tbody .span_red {
    font-size: 13px;
    color: red;
}

.inquiry_section1 .tbl_inquiry input[type="text"], .inquiry_section1 .tbl_inquiry select {
    font-family: 'open-sans', sans-serif;
    border: none;
    background: #e8eefe;
    height: 54px;
    border-radius: 10px;
    margin-top: 10px;
    padding: 0 20px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    color: #434343;
}


.inquiry_section1 .tbl_inquiry select {
    background: #e8eefe url(../image/icon_arrow.png) no-repeat 96% 50%;
}

.inquiry_section1 .tbl_inquiry .tbl_block {
    display: block;
    width: 100%;
}

.inquiry_section1 .tbl_inquiry .tbl_block select {
    width: 100%;
    background: #e8eefe url(../image/icon_arrow.png) no-repeat 98% 50%;
}

.inquiry_section1 .tbl_inquiry .tbl_block textarea {
    width: 100%;
    font-family: 'open-sans', sans-serif;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    background: #e8eefe;
    border: none;
    border-radius: 10px;
    outline: none;
}

.inquiry_section1 .inquiry_wrap .btn_wrap {margin-top: 40px;}
.inquiry_section1 .inquiry_wrap button {
    margin: 0 auto;
    display: inherit;
    background-image: linear-gradient(to right, #9e57ed 0%, #664efd);
    border-radius: 4px;
    border: none;
    color: #fff;
    padding: 14px 50px;
    font-size: 20px;
}

.inquiry_section1 .inquiry_wrap .checked_wrap {
    margin-top: 20px;
}

.inquiry_section1 .inquiry_wrap .checked_wrap .label_checked {
    display: block;
    font-size: 17px;
    color: #999;
    margin-bottom: 8px;
}

.inquiry_section1 .inquiry_wrap .checked_wrap .input_checked {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

