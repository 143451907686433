@charset "UTF-8";

/* ====== 1005 Emotion visual ====== */
#container.emotion_container .main_wrap {background: url(../image/emotion_visual-1.png) no-repeat;}



/* ======= 1005 Emotion section3 ======= */
.emotion_section3 {background:  #eaf3fe;}
.emotion_section3 .section3_inner {height: 1106px}


/* section3 */
.emotion_section3 .section3_inner img {margin-top: 20px;}
.emotion_section3 .section3_box {width: 100%;}


.emotion_section3 .effect_txt_wrap {float: right;}

.emotion_section3 .effect_txt_wrap li {
    position: relative;
    display: block;
    width: 520px;
    height: auto;
    margin-bottom: 32px;
    margin-right: 0;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 40px 0 rgba(145, 108, 206, 0.1);
    padding: 30px 30px 30px 40px;
    cursor: default;
}

.emotion_section3 .effect_txt_wrap .effect_txt1:after, 
.emotion_section3 .effect_txt_wrap .effect_txt2:after, 
.emotion_section3 .effect_txt_wrap .effect_txt3:after {
    content: '';
    position: absolute;
    left: -22px;
    top: -15px;
    background: url(../image/emotion_icon-metro-quote-1.png) no-repeat;
    width: 40px;
    height: 40px;
}

.emotion_section3 .effect_txt_wrap .effect_txt2:after {
    background: url(../image/emotion_icon-metro-quote-2.png) no-repeat;
}

.emotion_section3 .effect_txt_wrap .effect_txt3:after {
    background: url(../image/emotion_icon-metro-quote-3.png) no-repeat;
}

.emotion_section3 .effect_txt_wrap li h4 {
    letter-spacing: -0.15px;
    font-family: "GongGothic";
    font-size: 28px;
}

.emotion_section3 .effect_txt_wrap li p {
    font-size: 20px;
    letter-spacing: -0.46px;
    color: #1a1a1a;
    line-height: 1.7;
}

.emotion_section3 .effect_txt_wrap li:last-child {
    margin-top: 0;
    margin-bottom: 0;
}

.emotion_section3 .section3_box .exam_text_box {padding-top: 30px;}
.emotion_section3 .section3_box .exam_text_box .exam_tit {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.11px;
    color: #0e152e;
}

.emotion_section3 .section3_box .exam_text_box .exam_txt {
    color: #434343;
    display: block;
    position: relative;
    padding: 20px 0 0 30px;
}

.emotion_section3 .section3_box .exam_text_box .exam_txt::before {
    content: '';
    position: absolute;
    left: 0;
    top: 26px;
    background: url(../image/icon-check.png) no-repeat;
    width: 15px;
    height: 12px;
}


/* ======= 20230327 Emotion section1 ======= */
.emotion_section1 {background: #edeeff;}
.emotion_section1 .section1_inner {height: 746px;}

.emotion_section1 .section1_box {
    display: flex;
    justify-content: space-between;
}

.emotion_section1 .section1_box .section1_content {width: 46%;}

.emotion_section1 .section1_box .section1_content .section1_tit {
    position: relative;
    font-family: "Montserrat";
    font-size: 30px;
    color: #464dee;
    font-weight: 700;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    padding: 14px 0;
}

.emotion_section1 .section1_box .section1_content .section1_tit::before {
    content: '';
    position: absolute;
    left: 0;
    top: -16px;
    background: url(../image/emotion_icon-metro-quote-1.png) no-repeat;
    width: 40px;
    height: 32px;
}

.emotion_section1 .section1_box .section1_content li {
    padding-top: 28px;
}

.emotion_section1 .section1_box .section1_content .left_sub_tit {
    font-family: "GongGothic";
    font-size: 20px;
    letter-spacing: -0.12px;
    color: #6c72ff;
    padding-bottom: 16px;
}

.emotion_section1 .section1_box .section1_content .left_sub_tit::before {
    content: '\2022';
    color: #6c72ff;
    padding-right: 5px;
}

.emotion_section1 .section1_box .section1_content .left_sub_txt {
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: -0.46px;
    color: #1a1a1a;
    padding-left: 12px;
    display: inline-block;
}

.emotion_section1 .section1_box .section1_content .right_sub_txt {
    position: relative;
    font-size: 19px;
    line-height: 1.6;
    letter-spacing: -0.46px;
}

.emotion_section1 .section1_box .section1_content .right_sub_txt::before {
    content: '\2022';
    color: #6c72ff;
    padding-right: 5px;
}

/* ======= 20230327 Emotion section2 ======= */
.emotion_section2 {background: #fff;}
.emotion_section2 .section2_inner {height: 1100px;}

.emotion_section2 .section2_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.emotion_section2 .section2_box ul {margin-top: 60px;}
.emotion_section2 .section2_box ul li {
    border-radius: 7.2px;
    padding: 30px 25px 30px 30px;
    margin-bottom: 40px;
}
.emotion_section2 .section2_box ul li:nth-child(odd){background: #edeeff;}
.emotion_section2 .section2_box ul li:nth-child(even){background: #eaf3fe;}
.emotion_section2 .section2_box ul li p {
    font-size: 22px;
    line-height: 1.36;
    letter-spacing: -0.5px;
    color: #464dee;
}


/* ======= 1005 Emotion section4 ======= */
.emotion_section4 {background: url(../image/emotion-bg-1.png) no-repeat; background-size: 100%;}
.emotion_section4 .section4_inner {height: 900px;}
