@charset "UTF-8";

/* 20230322 main visual */
#container.cg_container .main_wrap{
    background: url(../image/CaptionGeneration-mainVisual.png) no-repeat;
    background-size: cover;
}

#container.cg_container .main_visual_title.cg_title {top: -50px;}
#container.cg_container .main_visual_title.cg_title p {margin: 20px 0;}
#container.cg_container .main_visual_title.cg_title span {
    display: inline-block; 
    padding-bottom: 7px;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.13px;
    line-height: 1.76;
    color: #333;
}

.cg_container .main_wrap .sub_title_wrap {display: flex;}
.cg_container .main_wrap .left_sub_title {margin-right: 50px;}
.cg_container .main_wrap .sub_title_wrap ul li {
    line-height: 1.88;
    letter-spacing: -0.09px;
}


/* 20230322 captionGeneration section1 */
.cg_container .cg_section1 {background: #edeeff;}
.cg_container .cg_section1 .cg_section1_inner {height: 880px;}
.cg_container .cg_section1 .section_title {
    padding: 0 0 45px;
}

.cg_container .cg_section1 .section_title span {
    font-family: "Montserrat";
    font-weight: 700;
}

.cg_container .cg_section1 .section_sub_title {
    font-size: 40px;
    font-family: "GongGothic";
    text-align: center;
    color: #464dee;
    padding-bottom: 75px;
}

.cg_container .cg_section1 .section_sub_title .left_quote {padding-right: 40px;}
.cg_container .cg_section1 .section_sub_title .right_quote {padding-left: 40px;}
.cg_container .cg_section1 .section1_content_txt {
    font-size: 24px;
    line-height: 1.83;
    letter-spacing: -0.4px;
    text-align: center;
    color: #0e152e;
    padding-top: 58px;
}


/* 20230322 captionGeneration section2 */
.cg_container .cg_section2 {background: #382496;}
.cg_container .cg_section2 .cg_section2_inner {
    width: 1380px;
    height: 1080px;
}

.cg_container .cg_section2 .cg_section2_inner img {vertical-align: middle;}

/* 20230322 captionGeneration section3,4 */
.cg_container .cg_section3 {background: #111;}
.cg_container .cg_section3 .cg_section3_inner,
.cg_container .cg_section4 .cg_section4_inner {height: 967px;}
.cg_container .cg_section3 .section3_content,
.cg_container .cg_section4 .section4_content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.cg_container .cg_section3 .section3_content img {
    vertical-align: middle;
    position: relative;
    right: -70px;
}

.cg_container .cg_section3 .section3_content .text_box .text_box_tit,
.cg_container .cg_section4 .section4_content .text_box .text_box_tit {
    font-size: 40px;
    font-weight: 700;
    font-family: "Montserrat";
    letter-spacing: -0.21px;
    color: #52f0ff;
}

.cg_container .cg_section3 .section3_content .text_box .text_box_txt,
.cg_container .cg_section4 .section4_content .text_box .text_box_txt {
    display: inline-block;
    line-height: 2;
    color: #fff;
    padding: 20px 12px 20px 0;
}

.cg_container .cg_section4 {background: #1d1e20;}
.cg_container .cg_section4 .section4_content .text_box {padding-right: 70px;} 
.cg_container .cg_section4 .section4_content .text_box .text_box_txt {padding: 10px 0 20px;}

/* 20230322 captionGeneration section5 */
.cg_container .cg_section5 .btn_demo {
    background: #fff;
    color: #464dee;
    font-weight: 700;
    margin-top: -2px;
}

