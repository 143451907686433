@charset "UTF-8";

/* 메인비주얼 */
#container.sub_container .main_wrap {background: url(../image/main_img.png) no-repeat;}

#container.sub_container .white_title {
    top: 0;
    justify-content: center;
    align-items: center;
}

#container.sub_container .white_title p {color: #fff;}

/* ====== 0930 main section1 ====== */
.main_section1 .section1_inner {height: 1000px;}
.main_section1 li {
    display: inline-block;
    background: rgba(233, 239, 255, 0.1);
    border-radius: 15px;
    width: 47.8%;
    height: 290px;
}

.main_section1 li:hover a {
    border: 1px solid  #a7aaff;
    box-shadow: 0 0 40px 0 rgba(138, 143, 255, 0.28);;
}

.main_section1 li:nth-child(odd) {margin-right: 50px;}
.main_section1 li:nth-child(n+2):nth-child(n+3) {margin-top: 50px;}

.main_section1 li a {
    border-radius: 15px;
    display: inline-block;
    padding: 30px 30px 70px 40px;
}

.main_section1 li img {
    position: relative;
    top: 20px;
    float: right;
    width: 290px;
    vertical-align: middle;

}

.main_section1 li .txt_box {
    width: 200px;
    overflow: hidden;
}

.main_section1 li .section_txt {float: left; line-height: 30px;}

.main_section1 li h3 {
    font-size: 28px;
    color: #a7aaff;
    font-family: "Montserrat";
    font-weight: 700;
    margin-bottom: 20px;
}

/* ======= 1004 main section2 ======= */
.main_section2 {background: #1d1e20; position: relative;}
.main_section2 .section2_inner {height: 2400px;}
.main_section2 .section2_box {
    width: 100%;
    padding-right: 30px;
    box-sizing: border-box;
}
.main_section2 .section2_content {
    display: flex;
    margin-bottom: 100px;
    justify-content: space-between;
}

.main_section2 .section2_content:last-child {margin-bottom: 0;}
.main_section2 .section2_content img {width: 580px;}
.main_section2 .section2_txt {
    width: 550px;
    padding: 10px 0px 80px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.main_section2 .section2_tit {
    font-size: 48px;
    color: #52f0ff;
    font-family: "GongGothic";
    margin-bottom: 20px;
    letter-spacing: -0.29px;
}

.main_section2 .section2_tit.yellow {color: #ffd266;}
.main_section2 .section2_tit.purple {color: #e196ff;}
.main_section2 .section2_tit.green {color: #34ea9c;}



.main_section2 .section2_box p {
    font-size: 24px;
    color: #d4d4d4;
    line-height: 1.67;
    letter-spacing: -0.4px;
}


/* 1005 right_txt, square */
.main_section2 .main_right_txt {
    position: absolute;
    right: 130px;
    top: 156px;
    font-family: "GongGothic";
    font-size: 38px;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
    z-index: 1;

}

.main_section2 .main_right_txt.on {position: fixed;}

.main_section2 .main_right_txt span {color: #fff;}

.main_section2 .square {
    animation: rotation 3s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.main_section2 .square1, .main_section2 .square2, 
.main_section2 .square3, .main_section2 .square4, 
.main_section2 .square5 {
    position: absolute;
    top: 130px;
    left: 200px;
    width: 16px;
    height: 16px;
    background: #3e7fff;
    border-radius: 3px;
    transform: rotate(30deg);
}

.main_section2 .square2 {
    background: #ffef82;
    transform: rotate(-75deg);
    left: auto;
    right: 370px;
    top: 30%;
    margin-top: 40px;
}

.main_section2 .square3 {
    background: #eb4788;
    transform: rotate(-174deg);
    left: auto;
    right: 210px;
    top: 50%;
    margin-top: 30px;
}

.main_section2 .square4 {
    background: #ff8839;
    transform: rotate(-129deg);
    top: auto;
    bottom: 30%;
    left: 160px;
}

.main_section2 .square5 {
    background: #7047eb;
    transform: rotate(-30deg);
    top: auto;
    left: auto;
    bottom: 400px;
    right: 230px;
}

