@charset "UTF-8";

/* ====== 1005 Visual Attention visual ====== */
#container.va_container .main_wrap {background: url(../image/visualAttention_visual1.png) no-repeat;}

#container.va_container .main_visual_title .comment {
    color: #464dee; 
    font-size: 16px;
}

/* ======= 1005 Visual Attention section1 ======= */
.va_section1 {background: #382496;}
.va_section1 .section1_inner {height: 970px;}



/* ======= 1005 Visual Attention section2 ======= */
.va_section2 {background: #eaf3fe;}
.va_section2 .section2_inner {height: 1160px;}
.va_section2 .section2_inner .section2_box {width: 100%;}

.va_section2 .section2_box ul {
    display: flex;
    justify-content: space-between;
}

.va_section2 .section2_box ul li img {vertical-align: baseline;}

.va_section2 .section2_box ul li .content_txt {
    text-align: center;
    padding: 30px 0;
    background: #fff;
    margin-top: -8px;
    border-radius: 0px 0px 22px 22px;
}

.va_section2 .section2_box ul li .content_txt p {
    font-family: "GongGothic";
    font-size: 30px;
    color: #0e152e;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
}

.va_section2 .section2_box ul li .content_txt span {
    font-size: 18px;
    line-height: 1.67;
    color: #0e152e;
    letter-spacing: -0.41px;
}


/* ======= 1005 Visual Attention section3 ======= */
.va_section3 {
    background-image: radial-gradient(circle at 50% 40%, #9e57ed, #664efd 139%);

}
.va_section3 .section3_inner {height: 1080px; justify-content: flex-end;}
.va_section3 .section3_inner .section_title.small {color: #fff !important;}

/* ======= 1005 Visual Attention section4 ======= */
.va_section4 .section4_inner {height: 1000px;}




