@charset "UTF-8";


/* ======= 1005 detection section1 20230324 section1 수정======= */
#container.detection_container .main_wrap {
    background: url(../image/detaction-mainVisual.png) no-repeat !important;
}


/* ======= 1005 detection section2 ======= */
.detection_section2 {background: #eaf3fe;}
.detection_section2 .detection_section2_inner {height: 1485px;}
.detection_section2 .section2_content {
    display: flex;
    padding-top: 80px;
}

.detection_section2 .section2_content:first-child {padding-top: 0;}

.detection_section2 .section2_content .section2_txt {
    width: 550px;
    padding: 0px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.detection_section2 .section2_tit {
    font-size: 40px;
    color: #464dee;
    font-family: "Montserrat";
    letter-spacing: -0.21px;
    font-weight: 700;
}

.detection_section2 .sub_txt p {
    line-height: 2;
    color: #434343;
    padding-top: 25px;
}


/* ======= 1005 detection section3 ======= */
.detection_section3 {background: #382496;}
.detection_section3 .section3_inner {height: 1080px;}
.detection_section3 .section3_inner .section_title.small {color: #fff !important;}
.detection_section3 .section3_box {display: flex;}

.detection_section3 .section3_box .section3_content {
    width: 33.3%;
    margin-right: 40px;
}

.detection_section3 .section3_box .section3_content:last-child {
    margin-right: 0;
}
.detection_section3 .section3_box .section3_content img {width: 100%;}
.detection_section3 .section3_box .section3_content .section3_content_txt {
    background: #fff;
    height: 330px;
    padding: 30px 20px 45px;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    text-align: center;
}

.detection_section3 .section3_box .section3_content:last-child .section3_content_txt {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 35px;
}

.detection_section3 .section3_box .section3_content .section3_content_txt p {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-align: center;
    color: #0e152e;
    font-family: "Montserrat";
    padding-bottom: 20px;
}

.detection_section3 .section3_box .section3_content .section3_content_txt span {
    font-size: 18px;
    font-family: 'SpoqaHanSansNeo';
    line-height: 1.67;
    letter-spacing: -0.41px;
    text-align: center;
    color: #0e152e;
    opacity: 0.65;
}

.detection_section3 button {
    margin-top: 26px;
    color: 
}


